import React from 'react';
var DEFAULT_ORG_LOGO = 'https://turbine-content.s3.us-east-2.amazonaws.com/default-org-logo.jpg';
var OrgLogo = function (_a) {
    var _b = _a.height, height = _b === void 0 ? 38 : _b, _c = _a.width, width = _c === void 0 ? 100 : _c, _d = _a.className, className = _d === void 0 ? '' : _d, src = _a.src, alt = _a.alt;
    return (React.createElement("img", { role: 'img', "data-testid": 'org-logo', src: src || DEFAULT_ORG_LOGO, className: "".concat(className, " tw-w-auto tw-object-contain"), alt: alt || 'Organization Logo', style: {
            maxWidth: "".concat(width, "px"),
            maxHeight: "".concat(height, "px"),
            height: 'auto'
        } }));
};
export default OrgLogo;
